.drawer-screen {
    background-color: var(--background-color);
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
}

.drawer-screen-content {
    display: flex;
    flex: 1;
    height: 100%;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    background-color: white;
}

.hamburger-menu {
    display: none;
    cursor: pointer;
    padding: 15px;
    z-index: 100;
}

.hamburger-menu div {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 5px 0;
    transition: all 0.3s ease;
    border-radius: 2px;
}

.hamburger-menu.opened div:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.hamburger-menu.opened div:nth-child(2) {
    opacity: 0;
}

.hamburger-menu.opened div:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}

@media screen and (max-width: 768px) {
    .hamburger-menu {
        display: block;
        position: fixed;
        top: 20px;
        left: 20px;
    }
    
    .drawer {
        position: fixed;
        left: -100%;
        transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    }
    
    .drawer.opened {
        left: 0;
    }
}


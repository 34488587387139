@font-face {
    font-family: 'Satoshi';
    src: url('../../fonts/WEB/fonts/Satoshi-Regular.woff2') format('woff2'),
         url('../../fonts/WEB/fonts/Satoshi-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  .header {
    font-family: 'Satoshi', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    width: 100%;
    height: 5%;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, height 0.3s ease;
}

.header:hover {
    cursor: pointer; 
}

.header:hover img {
    transform: scale(1.1); 
    transition: transform 0.3s ease;
}


.modal-content:hover {
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
}




.modal-image-container {
  flex: 0 0 40%;
  margin-right: 30px;
}


@media (max-width: 768px) {
  .modal-content {
    flex-direction: column;
    height: 90vh;
  }

  .modal-left, .modal-right {
    width: 100%;
    padding: 0;
  }

  .modal-right {
    border-left: none;
    border-top: 1px solid #e9ecef;
    padding-top: 20px;
    margin-top: 20px;
    overflow-y: auto;
  }

  .modal-content-wrapper {
    flex-direction: column;
  }


  .premium-features {
    flex: 0 0 100%;
  }

  .subscription-plans-container {
    flex-direction: column;
    align-items: center;
  }

  .planButton {
    width: 100%;
    max-width: none;
  }
}


  .premiumOverlay, .premiumSelectionOverlay {
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    animation: fadeIn 0.4s ease-out;
    padding-top: 1vh; 
  }
  
  .premiumSelectionContent {
    width: 90%; 
    max-width: 500px; 
    background-color: #f8f9fa;
    border-radius: 12px; 
    padding: 25px; 
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    max-height: 60vh;
    overflow-y: auto;
    animation: slideIn 0.4s ease-out;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
  }
  
  .premiumSelectionContent::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg,#e64400,#e64400);
    border-radius: 12px 12px 0 0;
  }
  
  .premiumSelectionContent:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  }
  
  .premiumSelectionContent::-webkit-scrollbar {
    width: 6px;
  }
  
  .premiumSelectionContent::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 0 12px 12px 0;
  }
  
  .premiumSelectionContent::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  .premiumSelectionContent::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  

    @media (max-width: 768px) {
      .premiumSelectionContent {
        width: 85%; 
        padding: 15px; 
        margin: 0 auto; 
        max-height: 65vh; 
      }

    }
  

  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    width: 100%;
    padding: 8px;
    border-radius: 20px;
    background-color: #fff;
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
  }
  
  .header-container:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
    transform: translateY(-2px);
  }
  
  .title-with-image {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .subscription-title {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    line-height: 1.4;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .modal-subheader {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 4px;
  }
  
  .header-container:hover .subscription-title {
    color: #ff4a00;
    transform: translateX(3px);
  }
  
  .active-button {
    font-size: 14px;
    background-color: rgba(255, 74, 0, 1);
    color: white;
    width: 28%;
    border: none;
    border-radius: 20px;
    padding: 2px 12px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}


  .header-container:hover .active-button {
    background-color: #e64400;
    transform: scale(1.05);
  }
  
  .selected-title {
    font-weight: bold;
    color: rgba(255, 74, 0, 1);
    font-size: 20px;
  }
  
  .row-center {
    display: flex;
    align-items: center;
  }
  
  img {
    transition: transform 0.3s ease;
  }
  
  .header-container:hover img {
    transform: scale(1.1);
  }
  
  .selected-indicator {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }
  
  .tickImage {
    position: absolute;
    right: 0;
    padding: 10px;
  }
  
  

.data-control-section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    width: 100%;
    margin-left: 70px;
    padding: 0 20px;
    box-sizing: border-box;
}

.data-section {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.data-input {
    width: 100%;
    max-width: 300px;
    padding: 8px;
    margin-top: 10px;
    box-sizing: border-box;
}

@media screen and (max-width: 768px) {
    .data-control-section {
        margin-left: 0;
        padding: 15px;
        align-items: center;
    }

    .data-section {
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
    }

    .data-input {
        max-width: 100%;
    }
}

.profile-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px 24px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.profile-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    width: 100%;
    margin: 16px 0;
}

.data-section-profile {
    width: 70%;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    transition: all 0.2s ease;
    background-color: #fff;
}

.data-section:hover {
    border-color: var(--primary-color);
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
    transform: translateY(-2px);
}

.data-title {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 0;
}

.data-label {
    font-size: 1.1rem;
    color: #2c3e50;
    margin-bottom: 0;
    line-height: 1.5;
    flex: 1;
    text-align: right;
}

.data-input {
    flex: 1;
    max-width: 70%;
    width: 100%;
    padding: 12px;
    border: 1px solid #eee;
    background-color: #fff;
    font-size: 1.1rem;
    border-radius: 8px;
    transition: all 0.2s ease;
}

.data-input:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.1);
}

textarea.data-input {
    min-height: 100px;
    resize: vertical;
}

.buttons-container {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
    gap: 16px;
    padding: 24px 0 8px;
    margin: 0 auto;
}

.edit {
    background-color: transparent;
    color: var(--primary-color);
    padding: 10px 24px;
    border: 2px solid var(--primary-color);
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: all 0.2s ease;
}

.edit:hover {
    background-color: var(--primary-color);
    color: white;
    transform: translateY(-1px);
}

@media screen and (max-width: 768px) {
    .profile-section {
        padding: 20px 16px;
        border-radius: 0;
        box-shadow: none;
    }
    
    .profile-grid {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin: 8px 0;
    }
    
    .data-section-profile {
        align-items: flex-start;
        padding: 16px;
        border: none;
        border-bottom: 1px solid #f0f0f0;
        border-radius: 0;
        display: flex;
        flex-direction: column;

    }
    
    .data-section:hover {
        transform: none;
        box-shadow: none;
        border-color: #f0f0f0;
    }
    
    .data-input {
        padding: 10px;
        font-size: 1rem;
    }
    
    .buttons-container {
        padding: 20px 0;
        flex-direction: column;
        gap: 12px;
    }
    
    .edit {
        width: 100%;
    }
    
    .data-title {
        margin-bottom: 8px;
    }
    
    .data-label, .data-input {
        width: 100%;
        text-align: left;
        max-width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .profile-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
}